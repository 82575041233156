import React from 'react';
import { useSelector } from 'react-redux';
import './LocationCard.scss';

const days = {
    'mon': {
        is: 'Mánudagur',
        en: 'Monday'
    },
    'tue': {
        is: 'Þriðjudagur',
        en: 'Tuesday'
    },
    'wed': {
        is: 'Miðvikudagur',
        en: 'Wednesday'
    },
    'thu': {
        is: 'Fimmtudagur',
        en: 'Thursday'
    },
    'fri': {
        is: 'Föstudagur',
        en: 'Friday'
    },
    'sat': {
        is: 'Laugardagur',
        en: 'Saturday'
    },
    'sun': {
        is: 'Sunnudagur',
        en: 'Sunday'
    },
}

const formatDate = dateString => {
    return dateString.slice(0, 2) + ':' + dateString.slice(2, 4)
}

const LocationCard = ({ title, subtitle, openingHours, times, action }) => {
    const lang = useSelector(state => state.lang);

    return (
        <div className="location-card">
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
            <div className="opening-hours">
                {
                    openingHours && Object.keys(openingHours).map(day =>
                        <p>
                            {days[day][lang]} {formatDate(openingHours[day].from)} - {formatDate(openingHours[day].to)}
                        </p>
                    )
                }
            </div>

            {action ?
                <button onClick={action.action}>
                    <img src={action.icon} alt={action.label} /> {action.label}
                </button>
                :
                <div className="times">
                    {times ?.map(time =>
                        <div className="time">
                            <p className="left">{time.label}</p>
                            <p className="right">{time.value} mín</p>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default LocationCard;