import { useQuery } from '@apollo/react-hooks';
import { ACTIVE_OFFERS } from '../graphql/queries';

function useOffers() {
    const { error, data, loading, refetch } = useQuery(ACTIVE_OFFERS, {
        notifyOnNetworkStatusChange: true,
    });

    if (loading && !data?.allOffers) return { loading, offers: [], totalCount: null };
    if (error) return { loading, offers: [], totalCount: null };

    console.log(data.activeOffers.edges.map(({ node }) => node));
    return {
        offers: data.activeOffers.edges.map(({ node }) => node),
        totalCount: data.activeOffers.totalCount,
        loading,
        refetch,
    };
}

export default useOffers;
