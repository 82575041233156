import React, { useEffect } from 'react';

import { CartItems } from '../';
import { useSelector, useDispatch } from "react-redux";
import { removeFromCart } from '../../../redux/actions/cart';
import { useBranches } from '../../../hooks';

import './Cart.scss';

const formatISK = price => price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0';
export default function Cart({ visible, toggle }) {
    const lang = useSelector(state => state.lang);
    const cart = useSelector(state => state.cart);
    const dispatch = useDispatch();


    const { branches, loading } = useBranches();
    const closed = (!loading && branches.every(b => !b.open) && branches.every(b => !b.delivering));

    function removeItem(index) {
        dispatch(removeFromCart(index))
    }

    useEffect(() => {
        // Disable body scroll side cart is open
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [visible]);

    if (!visible) return null;
    console.log(cart);

    return (
        <div className="cart-container">
            <div className="cart-modal">
                <CartItems cart={cart} lang={lang} removeItem={removeItem} />
            </div>
            <div className="checkoutBtn">
                <a href={`/${closed ? '' : 'checkout'}`}>
                    <button className="cart-button">
                        <h2 className="panta">{closed ? (lang === 'is') ? 'Lokað' : 'Closed' : (lang === 'is') ? 'Panta' : 'Order'}</h2>
                        <p className="cta-subtitle">{formatISK(cart.reduce((curr, acc) => (acc.pickupPrice ? Math.min(acc.pickupPrice, acc.deliveryPrice) : acc.price) + curr, 0))} kr.</p>
                    </button>
                </a>
            </div>
            <div className="cart-background" onClick={toggle} />
        </div>
    )
}