import React from 'react';
import './Section.scss';

const Section = ({ title, subtitle, children, wide }) => (
    <section className={`section ${wide ? 'wide' : ''}`}>
        <h1 className="title">{title}</h1>
        {subtitle && <h1 className="subtitle">{subtitle}</h1>}
        {children}
    </section>
)

export default Section;