import React from 'react';
import RemoveIcon from './assets/remove_icon.svg';
import { toppingsDelta } from "../../../services/PizzaHelpers";
import './CartItems.scss';

import { useToppings } from '../../../hooks';

const formatISK = price => price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0';
const CartItems = ({ cart, lang, removeItem, fixed = true }) => {
    const { toppings } = useToppings();
    const flatToppings = toppings.map(t => t.toppings).flat();
    
    const sizeChart = {
        small: {
            en: 'Small',
            is: 'Lítil'
        },
        medium: {
            en: 'Medium',
            is: 'Miðlungs'
        },
        large: {
            en: 'Large',
            is: 'Stór'
        },
    }
    
    return (
        <div className={`cart-items ${fixed ? 'fixed' : ''}`}>
            {
                cart.map(({ name, price, deliveryPrice, pickupPrice, type, description, toppings, size, isDivided, toppingsSelectionSide1, toppingsSelectionSide2, baseToppings1, baseToppings2 }, i) => {
                    return (
                        <div className="cart-item" key={i}>
                            <h3 className="item-name">{name && name[lang] || name} {size ? `( ${sizeChart[size][lang]} )` : ''}</h3>
                            {removeItem && <div className="remove" onClick={e => removeItem(i)}>
                                <img src={RemoveIcon} alt="taka úr körfu" />
                            </div>}

                            <p className="item-description">
                                {type === "Pizza" ?
                                    <>
                                        {flatToppings ?.length && toppingsDelta(baseToppings1, toppingsSelectionSide1.map(topping => ({ ...topping, name: flatToppings.find(t => t.id === topping.id)?.name })))}
                                        {!!isDivided && <>// <br /></>}
                                        {!!isDivided && flatToppings ?.length && toppingsDelta(baseToppings2, toppingsSelectionSide2.map(topping => ({ ...topping, name: flatToppings.find(t => t.id === topping.id)?.name })))}
                                    </>
                                    :
                                    description && (typeof description === 'string' ? description : description[lang] || '')
                                }
                            </p>
                            {
                                (pickupPrice && deliveryPrice) ?
                                    <div className="item-price">{formatISK(Math.min(pickupPrice, deliveryPrice))} kr. </div>
                                    :
                                    <div className="item-price">{formatISK(price)} kr.</div>
                            }
                        </div>
                    )
                })
            }
            <div className="padding-space" />
        </div>
    )
}

export default CartItems;