import React from "react";
import { Section, LocationCard } from "../components/UI";

// import transcript from "../i18n/AllergiesPage";
import { useSelector } from "react-redux";

import allergiesTable from "../assets/images/ofnaemisvaldar.svg";
import kebabAllergiesTable from "../assets/images/ofnaemisvaldar_kebab.svg";

const AllergiesPage = () => {
  const lang = useSelector((state) => state.lang);

  return (
    <Section title={"Ofnæmisvaldar"} wide>
      <br />
      <br />
      <h2>Pizzur og réttir</h2>
      <img src={allergiesTable} alt="Ofnæmisvaldar Castello" />

      <br />
      <br />
      <h2>Kebab</h2>
      <img
        src={kebabAllergiesTable}
        alt="Ofnæmisvaldar - Kebab og hamborgarar"
      />

      <h2>Nánar</h2>
      <div>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.7172470092773438pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "22.01597023010254pt",
              fontFamily: '"Arial Narrow", sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Innihaldslýsing matvæla - Pítsur og álegg&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "24.59954833984375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Botn&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.14409637451171875pt",
            textIndent: "0.816558837890625pt",
            marginTop: "0.3302001953125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Hveiti&nbsp;</span>
          <span>
            (auðgað með níasín, skert járn, þíamín mónónítrat, ríbóflavín,
            fólinsýra, maltað byggmjöl), vatn, repjuolía, Víking pilsner léttöl
            (maltað bygg), salt, sykur, ger&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "36.80291748046875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Kjötálegg&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.3453369140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Pepperoni</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.44830322265625pt",
            marginRight: "1.02313232421875pt",
            textIndent: "0.14410400390625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Svínakjöt, grísaspekk, salt, rotvarnarefni (E250), krydd (
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnepsduft</span>
          <span>
            , paprika extrakt, sykur, sólblómaolía), þrávarnarefni (E301)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.4752197265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Skinka</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginRight: "32.8399658203125pt",
            textIndent: "-0.25617218017578125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.25617218017578125pt",
          }}
        >
          <span>
            Svínakjöt,vatn, glúkósasýróp, kartöflusterkja, bindiefni (E450,
            E451), þykkingarefni (E407), kryddþykkni, þrávarnarefni (E316),
            bragðefni, salt, rotvarnarefni (E250), kartöflumjöl, maltódextrín,
            sólblómaolía&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.787811279296875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Nautahakk</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span>Ungnautakjöt, kryddblanda (salt, krydd, sykur)&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.096099853515625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Beikonsneiðar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginRight: "30.897491455078125pt",
            textIndent: "-0.06404876708984375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.06404876708984375pt",
          }}
        >
          <span>
            Grísakjöt, vatn, salt, bindiefni (E450), þrávarnarefni (E301),
            rotvarnarefni (E250)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "17.724517822265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hráskinka</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginRight: "46.723175048828125pt",
            textIndent: "-0.12808990478515625pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.12808990478515625pt",
          }}
        >
          <span>Grísahnakki, salt, glúkósasýróp,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>mjólkursykur</span>
          <span>, krydd (</span>
          <span style={{ fontWeight: 900, color: "red" }}>sellerí</span>
          <span>
            ), sykur, rotvarnarefni (E250), þrávarnarefni (E301), sýra
            (E330)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "19.100387573242188pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Kjúklingur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7845382690429688pt",
            marginRight: "4.228515625pt",
            textIndent: "0.19213104248046875pt",
            textAlign: "justify",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Kjúklingur, krydd, þrúgusykur, sykur, salt, kartöflumjöl, vatnsrofið
            jurtaprótein (jurta, maís), reykt kóríander, reykbragðefni,
            jurtaolía (repju), sýrustillar, sýrur (E262, E330, E331),
            þrávarnarefni (E316)
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "30.168983459472656pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7985687255859375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Ostar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.3453369140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Pizzaostur - Blanda</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.8805999755859375pt",
            marginRight: "0.81298828125pt",
            textIndent: "0.04803466796875pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>
            , salt, ostahleypir, rotvarnarefni (E252), mjólkursýrugerlar,
            kekkjavarnarefni(E460ii), ediksýra (E260)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "19.1004638671875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Piparostur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5763931274414062pt",
            marginRight: "1.004638671875pt",
            textIndent: "-0.2081451416015625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.2081451416015625pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Ostur</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>smjör</span>
          <span>
            , bræðslusölt (E450, E452), svartur og hvítur pipar, rotvarnarefni
            (E202)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.4752197265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Parmesan</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9286346435546875pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>, salt, ostahleypir&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Cheddar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7845382690429688pt",
            marginRight: "21.4700927734375pt",
            textIndent: "0.14409637451171875pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>
            , salt, ostahleypir, kekkjavarnarefni (sellolósi), litarfefni
            (annattó)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "19.100555419921875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Rjómaostur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.864593505859375pt",
            marginRight: "15.384185791015625pt",
            textIndent: "0.0640411376953125pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>áfir</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>rjómi</span>
          <span>
            , salt, bindiefni (gúargúmmí), rotvarnarefni (kalíumsorbat),
            mjólkursýrugerlar&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginTop: "19.1004638671875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Gráðaostur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Nýmjólk</span>
          <span>
            , salt, ostahleypir, mjólkursýrugerlar, Penicillium roqueforti&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Camembert</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Nýmjólk</span>
          <span>
            , salt, ostahleypir, mjólkursýrugerlar, Penincillium candidum&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.0640411376953125pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Vegan ostur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.0640411376953125pt",
            marginRight: "64.94500732421875pt",
            textIndent: "-0.35224151611328125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.35224151611328125pt",
          }}
        >
          <span>
            Vatn, kókosolía, umbreytt sterkja, sjávarsalt, mozzarella bragðefni,
            ólífuextrakt, litarfefni (B-karótín), B12 vítamín
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "120.68264770507812pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.8556060791015625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Grænmeti&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0.3302001953125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Sveppir</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Sveppir&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Paprika</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Rauð paprika&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.3362274169921875pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Tómatar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.3362274169921875pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span>Tómatar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9446487426757812pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Laukur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9446487426757812pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Laukur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Grænar ólífur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0.08099365234375pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Steinlausar ólífur, vatn, salt, sýrustillir (sítrónusýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.09613037109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Svartar ólífur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Svartar ólífur, vatn, salt, litarfestir (járnglúkónat)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Maís</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>Maískorn&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Basilika</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Basilika&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlaukur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>Hvítlaukur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "17.34552001953125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Þistilhjörtu</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "5.83160400390625pt",
            textIndent: "0.19213104248046875pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Skorin þistilhjörtu, vatn, repjuolía, eimað edik, salt, hvítlaukur,
            krydd,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnep</span>
          <span>
            , sýrustillir (sítrónusýra), andoxunarefni (askórbínsýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "19.100326538085938pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Brokkolí</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>Brokkolí</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "46.74169921875pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Klettasalat</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>Klettasalat&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Rauðlaukur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Rauðlaukur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "18.09625244140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Jalapeño</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Jalapeño paprika, vatn, joðað salt, edik, krydd, bindiefni
            (E509)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.2488555908203125pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Iceberg</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.2488555908203125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Iceberg&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.09625244140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Sólþurrkaðir tómatar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.8485794067382812pt",
            marginRight: "63.30291748046875pt",
            textIndent: "0.1601104736328125pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Hálfþurkkaðir tómatar, sólblómaolía, kryddblanda, eplaedik, salt,
            hvítlauksduft, sýrustillir (sítrónusýra), andoxunarefni
            (askórbínsýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "55.881256103515625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Ávextir&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.345306396484375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Ananas</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span>Ananas, vatn, sykur, sýrustillir (sítrónusýra)&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.09625244140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Bananar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Bananar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.99267578125pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Döðlur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.99267578125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Döðlur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "18.09625244140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Jarðaber</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Jarðber</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "102.288330078125pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7034988403320312pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Sjávarréttir&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "14.5921630859375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Rækjur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Kaldsjávarrækja</span>
          <span>, vatn, salt&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.3362274169921875pt",
            marginTop: "14.34283447265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Túnfiskur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.28820037841796875pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Túnfiskur</span>
          <span>, sólblómaolía, vatn, salt&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "14.34283447265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Kræklingur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>
            Kræklingur&nbsp;
          </span>
          <span>(lindýr, gæti innihaldið krabbadýr), vatn&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7034988403320312pt",
            marginTop: "17.345458984375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Sósur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "12.3402099609375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>
            Pizzasósa / Brauðstangasósa
          </span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginRight: "7.33251953125pt",
            textIndent: "-0.224151611328125pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.224151611328125pt",
          }}
        >
          <span>
            Smátt saxaðir tómatar (tómatar, tómatsafi, sýrustillir:
            sítrónusýra), fersk basilika, oregano, hvítlaukspipar (svartur
            pipar, hvítlaukur, salt, steinselja), hvítlaukssalt (salt,
            hvítlaukur, laukur), piparbland (svartur pipar,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnepsfræ</span>
          <span>, laukur, rauð belgpaprika,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>sellerí</span>
          <span>. hvítlaukur), cayenne pipar, paprikuduft&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "14.388275146484375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlaukssósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Repjuolía,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>eggjarauður</span>
          <span>, hvítlaukur, steinselja, sítrónusafi&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "13.59234619140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Jógúrtsósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6404342651367188pt",
            marginRight: "29.228363037109375pt",
            textIndent: "-0.22415924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.22415924072265625pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Grísk jógúrt</span>
          <span>
            , gúrka, sítrónusafi, edik (ediksýra 5%, krydd, rotvarnarefni),
            mynta, dill, steinselja, salt, svartur pipar&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "14.5966796875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Chillisósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "15.8214111328125pt",
            textIndent: "0.32021331787109375pt",
            marginTop: "0.08111572265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Grilluð paprika (paprika, vatn, salt, sykur, edik), chilli pipar,
            repjuolía, salsasósa (tómatar, tómatmauk, laukur, grænn chillipipar,
            jalapeño pipar, græn paprika, sykur, edik (ediksýra 5%, krydd,
            rotvarnarefni), vatn, salt, umbreytt sterkja, náttúruleg bragðefni,
            krydd, andoxunarefni (askórbínsýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "14.388275146484375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hamborgarasósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7845382690429688pt",
            marginRight: "8.30859375pt",
            textIndent: "0.14409637451171875pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Majónes&nbsp;</span>
          <span>
            (repjuolía, eggjarauður, vatn, krydd, sinnepsduft, edik, sykur,
            salt, rotvarnarefni E211, E202) , tómatsósa, gúrka relish (agúrka,
            sykur, edik, laukur, paprika, salt, umbreytt sterkja,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnepsfræ</span>
          <span>
            , rotvarnarefni (E202, E211), sýrustillir (kalsíumklóríð),
            náttúruleg bragðefni (cayenne pipar), krydd (þar með talið negull),
            litarefni (E141 og ríbóflavín)
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "5.554573059082031pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
          <span>
            <br />
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span style={{ textDecoration: "italic" }}>BBQ sósa</span>
          <span>&nbsp;</span>
        </p>
        <p dir="ltr">
          <span>
            Tómatmauk, edik, sýróp, sykur, melassi, umbreytt maíshveiti, salt,
            krydd, þykkingarefni (xanthan gum), hvítlaukur, reykbragðefni,
            náttúrleg bragðefni&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "19.100341796875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Tómatsósa</span>
          <span>&nbsp;</span>
        </p>
        <p dir="ltr">
          <span>
            Tómatþykkni, eimað edik, háfrúktósa kornsýróp, kornsýróp, salt,
            krydd, laukduft, náttúruleg bragðefni&nbsp;
          </span>
        </p>
        <p>
          <span style={{ textDecoration: "italic" }}>Kokteilsósa</span>
          <span>&nbsp;</span>
        </p>
        <p dir="ltr">
          <span>Sojaolía (erfðabreytt), vatn, tómatþykkni,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>eggjarauður</span>
          <span>, sykur, krydd (inniheldur fisk (</span>
          <span style={{ fontWeight: 900, color: "red" }}>ansjósur</span>
          <span>),&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>sellerí&nbsp;</span>
          <span>og&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>soja</span>
          <span>
            , umbreytt sterkja, bindiefni (E410, E412, E401), djúsþykkni, salt,
            edik,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnepsduft</span>
          <span>, rotvarnarefni (E202, E211, E260, E270)&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.8919677734375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Tabasco sósa</span>
          <span>&nbsp;</span>
        </p>
        <p dir="ltr">
          <span>Eimað edik, rauð paprika, salt&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "21.098846435546875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Olíur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.345062255859375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlauksolía</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span>
            Repjuolía, hvítlaukur, hvítlaukspipar (svartur pipar, hvítlaukur,
            salt, steinselja), hvítlaussalt (salt, hvítlaukur, laukur)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.474761962890625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Chilliolía</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.2", marginTop: "0pt", marginBottom: "0pt" }}
        >
          <span>
            Repjuolía, chilli pipar, cayenne pipar, hvítlaukssalt (salt,
            hvítlaukur, laukur)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Brauðstangaolía</span>
          <span>&nbsp;</span>
        </p>
        <p dir="ltr">
          <span>
            Smjörbragðbætt olía (Jurtaolía(repju- og pálmaolía, salt, ýruefni
            (lesitín9, bragðefni (inniheldur smjörolíu (
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>mjólk</span>
          <span>
            ), litarefni (Beta-Karótín)), krydd (pálmaolía, þurrkaðir
            hvítlaukur,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>ostur&nbsp;</span>
          <span>gerður úr kúamjólk (gerilsneydd&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>
            , salt, ensím), salt, umbreytt sterkja, steinselja, yellow five
            lake, náttúruleg bragðefni,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sojalestisín</span>
          <span>, kísildíoxíð&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "5.022006797790527",
            marginTop: "0.0220184326171875pt",
            marginBottom: "0pt",
          }}
        >
          <span>Algengir ofnæmisvaldar:&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>Soja</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>mjólkurafurðir</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>bygg&nbsp;</span>
          <span>og&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>egg</span>
          <span style={{ fontWeight: 900, color: "red" }}>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>
            <br />
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.8556060791015625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Glassúr&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.3455810546875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Súkkulaði glassúr</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginRight: "77.69094848632812pt",
            textIndent: "-0.19213104248046875pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.19213104248046875pt",
          }}
        >
          <span>Sykur, jurtafita,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>heslihnetur</span>
          <span>, kakóduft,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrennuduft</span>
          <span>, ýruefni (</span>
          <span style={{ fontWeight: 900, color: "red" }}>sojalesitín</span>
          <span>), bragðefni&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "19.100341796875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Karamella</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginRight: "35.786865234375pt",
            textIndent: "-0.06404876708984375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.06404876708984375pt",
          }}
        >
          <span>Glúkósa sýróp, sykruð niðurseydd&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>
            , vatn sykur, pálmaolía, umbreytt sterkja, ýruefni (mónó- og
            díglýseríð af fitusýrum,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sojalesitín</span>
          <span>
            ), náttúruleg bragðefni, sýrustillir (þrínatríumsítrat), salt,
            rotvarnarefni (kalíumsorbat), hlaupefni (pectin), sýra
            (vínsýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7034988403320312pt",
            marginTop: "17.932861328125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Sulta&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "13.0906982421875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Rifsberjahlaup</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginRight: "32.382720947265625pt",
            textIndent: "-0.19213104248046875pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.19213104248046875pt",
          }}
        >
          <span>
            Sykur, vatn, rifsberjaþykkni, hleypiefni (E440), sýra (E330),
            rotvarnarefni (E202)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "21.477996826171875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Krydd&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "13.841644287109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Brauðstangakrydd</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginRight: "12.35894775390625pt",
            textIndent: "-0.2401580810546875pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.2401580810546875pt",
          }}
        >
          <span>
            Salt, hvítlaukur, oregano, paprika, svartur pipar, cayenne pipar,
            paprikuduft, basil, steinselja, laukur&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.474761962890625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlaukspipar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Svartur pipar, hvítlaukur, salt, steinselja&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlaukssalt</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Salt, hvítlaukur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Chillipipar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Chillipipar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6724624633789062pt",
            marginTop: "18.09649658203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Oregano</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6724624633789062pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Oregano</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "28.7265625pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Basil</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0.08135986328125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Basil&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Græn piparkorn</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.720489501953125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Græn piparkorn&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Svartur pipar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Svartur pipar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Salt</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Salt&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "18.09649658203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Cayenne pipar</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Cayenne pipar&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Kanill</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Kanill malaður&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.99267578125pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Dill</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.99267578125pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Dill&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "17.345428466796875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>
            Kjúklingavængir&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "13.841644287109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>
            Kjúklingavængir í Bufflo sósu
          </span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "3.146484375pt",
            textIndent: "0.5763931274414062pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Kjúkingavængir, Buffalo sósa (Vatn, þrúgusykur, tómatar, krydd,
            umbreytt sterkja, sítróna, sykur, maltbragðefni, salt, gerextrakt,
            reykbragðefni, bindiefni (E1422, E412, E415), sýrustillar (E260,
            E330))&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginTop: "18.78814697265625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Annað&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.1047515869140625pt",
            marginTop: "10.088241577148438pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Flórsykur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.1047515869140625pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Flórsykur, maíssterkja&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.1047515869140625pt",
            marginTop: "14.34307861328125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Franskar kartöflur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.816558837890625pt",
            marginRight: "11.69439697265625pt",
            textIndent: "0.1601104736328125pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Kartöflur, sólblómaolía, umbreyt kartöflusterkja, hrísgrjónamjöl,
            salt, dextrín, lyftiduft (E450, E500), maltódextrín, þykkingarefni
            (E415), túrmerik, paprikuextrakt
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "5.710868835449219pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.7172470092773438pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "22.01597023010254pt",
              fontFamily: '"Arial Narrow", sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Innihaldslýsing matvæla - Kebab og hamborgarar&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "24.59942626953125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Brauð&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.3362274169921875pt",
            marginTop: "0.3304443359375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Tortilla brauð</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "4.3853759765625pt",
            textIndent: "0.5603790283203125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Hveiti</span>
          <span>
            , vatn, jurtaolía (repjuolía), sykur, ýruefni (E471), lyftiduft
            (E500, E450), salt, sýra (eplasýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.47515869140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Kebab brauð</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9606552124023438pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Hveiti</span>
          <span>
            , vatn, rapsolía, sykur, ger, salt, bindiefni (E472e),&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.864593505859375pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span>mjölmeðhöndlunarefni (E300),&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>
            gæti innihaldið snefil af sesam
          </span>
          <span>.&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.09649658203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hamborgarabrauð</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "4.36968994140625pt",
            textIndent: "0.5603790283203125pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Hveiti</span>
          <span>
            , vatn, sykur, smjörlíki (repju-, kókos- og pálmkjarnaolía, vatn,
            salt, bindiefni (E322 úr sólblómum, E471, E475), bragðefni,
            litarefni (E160a)), ger, salt, bindiefni (E472e, E412, E481), sýrur
            (E340 ,E300), kekkjavarnarefni (E170), þráavarnarefni (E340),
            sýrustillar (E339), mjölmeðhöndlunarefni (E300),&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>
            gæti innihaldið snefil af sesam
          </span>
          <span>.&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "18.78765869140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Kjöt&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9766693115234375pt",
            marginTop: "18.345550537109375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Kjúklingur</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.25617218017578125pt",
            marginRight: "25.530853271484375pt",
            textIndent: "0.7204971313476562pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Kjúklingur, repjuolía, salt, laukduft, paprika, svartur pipar,
            steinselja, chilli, túrmerik&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9446487426757812pt",
            marginTop: "18.47515869140625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Lambakjöt</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.5123519897460938pt",
            marginRight: "4.32281494140625pt",
            textIndent: "0.4322967529296875pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Lambakjöt, vatn, repjuolía, salt, laukduft, paprika, svartur pipar,
            hvítlauksduft, cumin, timjan, glúkósasýróp, bindiefni (E450, E451),
            sýrustillar (E640), þrávarnarefni (E300)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.788238525390625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hamborgarakjöt</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.8485794067382812pt",
            marginRight: "17.29107666015625pt",
            textIndent: "0.12808990478515625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Nautgripakjöt (Hlutfall kollagens af kjötpróteini er minna en 2%,
            kollagen er náttúrulegur hluti prótína í kjöti)
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "66.63607788085938pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7985687255859375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>Ostur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.3455810546875pt",
            marginBottom: "0pt",
          }}
        >
          <span>Hamborgaraostur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9286346435546875pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>, salt, ostahleypir, rotvarnarefni (E252)&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "17.34552001953125pt",
            marginBottom: "0pt",
          }}
        >
          <span>Cheddar hamborgaraostur&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.9286346435546875pt",
            marginTop: "0.08087158203125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Mjólk</span>
          <span>,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>undanrenna</span>
          <span>
            , salt, ostahleypir, rotvarnarefni (E252), litarefni (E160b)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.159820556640625pt",
            marginTop: "39.864501953125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontFamily: "Futura-Bold" }}>
            Kebab- og hamborgarasósur&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.3450927734375pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hvítlaukssósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>Repjuolía,&nbsp;</span>
          <span style={{ fontWeight: 900, color: "red" }}>eggjarauður</span>
          <span>, hvítlaukur, steinselja, sítrónusafi&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.35224151611328125pt",
            marginTop: "18.09600830078125pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Jógúrtsósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6404342651367188pt",
            marginRight: "29.228363037109375pt",
            textIndent: "-0.22415924072265625pt",
            marginTop: "0pt",
            marginBottom: "0pt",
            padding: "0pt 0pt 0pt 0.22415924072265625pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Grísk jógúrt</span>
          <span>
            , gúrka, sítrónusafi, edik (ediksýra 5%, krydd, rotvarnarefni),
            mynta, dill, steinselja, salt, svartur pipar&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.6244277954101562pt",
            marginTop: "18.474761962890625pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Chillisósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.40027618408203125pt",
            marginRight: "15.8214111328125pt",
            textIndent: "0.32021331787109375pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            Grilluð paprika (paprika, vatn, salt, sykur, edik), chilli pipar,
            repjuolía, salsasósa (tómatar, tómatmauk, laukur, grænn chillipipar,
            jalapeño pipar, græn paprika, sykur, edik (ediksýra 5%, krydd,
            rotvarnarefni), vatn, salt, umbreytt sterkja, náttúruleg bragðefni,
            krydd, andoxunarefni (askórbínsýra)&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "1.0086898803710938pt",
            marginTop: "18.683563232421875pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ textDecoration: "italic" }}>Hamborgarasósa</span>
          <span>&nbsp;</span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginLeft: "0.7845382690429688pt",
            marginRight: "8.30859375pt",
            textIndent: "0.14409637451171875pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span style={{ fontWeight: 900, color: "red" }}>Majónes (</span>
          <span>
            repjuolía, eggjarauður, vatn, krydd, sinnepsduft, edik, sykur, salt,
            rotvarnarefni E211, E202), tómatsósa, gúrka relish (agúrka, sykur,
            edik, laukur, paprika, salt, umbreytt sterkja,&nbsp;
          </span>
          <span style={{ fontWeight: 900, color: "red" }}>sinnepsfræ</span>
          <span>
            , rotvarnarefni (E202, E211), sýrustillir (kalsíumklóríð),
            náttúruleg bragðefni (cayenne pipar), krydd (þar með talið negull),
            litarefni (E141 og ríbóflavín)
          </span>
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.2",
            marginRight: "2.27606201171875pt",
            textAlign: "right",
            marginTop: "114.23959350585938pt",
            marginBottom: "0pt",
          }}
        >
          <span>
            <span
              style={{
                border: "none",
                display: "inline-block",
                overflow: "hidden",
                width: "127px",
                height: "76px",
              }}
            ></span>
          </span>
        </p>
      </div>
    </Section>
  );
};

export default AllergiesPage;
