import React from 'react';
import { Helmet } from 'react-helmet';

// UI
import { ProductNavigation, ToolBar, Product, ProductGrid, Section } from '../components/UI';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/actions/cart';

// Assets
import ShopIcon from '../assets/icons/shop.svg';

// Hooks
import { useMenuCategories, useMenuItems, usePizzas, useOffers, useDiscounts } from '../hooks';
import { toast } from 'react-toastify';

// Util
import { formatISK } from '../services/prices';
import { LargeImage } from '../services/cloudinary';

import transcript from '../i18n/CategoryPage';

const CategoryPage = (props) => {
    const category = props.match.params.slug;
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang);

    // Data hooks
    const { menuCategories } = useMenuCategories();
    const { menuItems } = useMenuItems();
    const { pizzas } = usePizzas();
    const { offers } = useOffers();
    const { discounts } = useDiscounts();

    const selectMenuCategory = (cat) => {
        props.history.push({
            pathname: `/${cat}`,
        });
    };

    let products;
    // Offers
    let pizzaOffers,
        kebabOffers,
        burgerOffers,
        otherOffers = [];
    let deliveryPizzaOffers = [];

    if (category === 'pizzas') {
        products = pizzas.sort((a, b) => a.priceLarge - b.priceLarge).map((pizza) => ({
            ...pizza,
            prices: [
                { label: '9"', price: pizza.priceSmall },
                { label: '12"', price: pizza.priceMedium },
                { label: '15"', price: pizza.priceLarge },
            ],
            action: () => {
                props.history.push({
                    pathname: `/pizza/${pizza.slug}`,
                });
            },
        }));
    } else if (category === 'offers') {
        pizzaOffers = offers
            .filter((off) => off.category === 'pizza' && off.isPickup)
            .map((offer) => ({
                ...offer,
                action: () => {
                    props.history.push({ pathname: `/offer/${offer.slug}` });
                },
            }));
        deliveryPizzaOffers = offers
            .filter((off) => off.category === 'pizza' && off.isDelivery)
            .map((offer) => ({
                ...offer,
                action: () => {
                    props.history.push({ pathname: `/offer/${offer.slug}` });
                },
            }));

        kebabOffers = offers
            .filter((off) => off.category === 'kebab')
            .map((offer) => ({
                ...offer,
                action: () => {
                    props.history.push({ pathname: `/offer/${offer.slug}` });
                },
            }));
        burgerOffers = offers
            .filter((off) => off.category === 'burger')
            .map((offer) => ({
                ...offer,
                action: () => {
                    props.history.push({ pathname: `/offer/${offer.slug}` });
                },
            }));
        otherOffers = offers
            .filter((off) => !off.category)
            .map((offer) => ({
                ...offer,
                action: () => {
                    props.history.push({ pathname: `/offer/${offer.slug}` });
                },
            }));
    } else {
        products = menuItems.find((menu) => menu.category.slug === category)?.items;
    }

    const selectProduct = (product) => {
        toast(`${product.name['is']} ${transcript[lang].addedToCart}`, { type: 'success' });

        // Appply product discounts
        product = applyProductDiscount(product);
        dispatch(addToCart({ ...product, type: 'MenuItem' }));
    };

    const applyMenuItemDiscount = (product) => {
        if (discounts.length) {
            if (category !== 'pizzas' && category !== 'offers') {
                let bestPrice = product.price;
                // Find all affecting discounts and calculate
                let active_discounts = discounts.filter(
                    (d) => d.discountType === 'MenuItem' && d.isDelivery && d.isPickup
                );
                active_discounts.map((discount) => {
                    if (discount.allowedItems.includes(product.id) || discount.isAllItems) {
                        let discountedPrice = Math.ceil(
                            product.price - product.price * (discount.discountPercentage / 100)
                        );
                        bestPrice = Math.min(discountedPrice, bestPrice);
                    }
                });
                // Return the lowest price
                return bestPrice;
            }
        }
        return product.price;
    };

    const applyPizzaDiscount = (product) => {
        if (category === 'pizzas') {
            let bestPrices = product.prices;
            let active_discounts = discounts.filter((d) => d.discountType === 'Pizza' && d.isDelivery && d.isPickup);

            active_discounts.map((discount) => {
                if (discount.allowedItems.includes(product.id) || discount.isAllItems) {
                    let discountedPrices = product.prices
                        .map((p) => p.price)
                        .map((price) => Math.ceil(price - price * (discount.discountPercentage / 100)));
                    bestPrices = product.prices.map((price, i) => ({
                        ...price,
                        price: Math.min(discountedPrices[i], bestPrices[i].price),
                    }));
                }
            });

            return bestPrices;
        }
        return product.prices;
    };

    const hasValidDiscounts = (product) => {
        let discountFound = null;
        if (category === 'offers') {
            return null;
        }
        let active_discounts = discounts.filter(
            (d) => d.discountType === (category === 'pizzas' ? 'Pizza' : 'MenuItem')
        );

        active_discounts.map((discount) => {
            if (discount.allowedItems.includes(product.id) || discount.isAllItems) {
                discountFound = discount;
            }
        });

        if (discountFound) {
            const { isDelivery, isPickup } = discountFound;
            if (isDelivery && isPickup) {
                return `${discountFound.discountPercentage}% ${transcript[lang].discount}`;
            } else if (isDelivery) {
                return `${discountFound.discountPercentage}% ${transcript[lang].discountDelivery}`;
            } else if (isPickup) {
                return `${discountFound.discountPercentage}% ${transcript[lang].discountPickup}`;
            }
            return null;
        }

        return null;
    };

    const applyProductDiscount = (product) => {
        if (discounts.length) {
            if (category !== 'pizzas' && category !== 'offers') {
                // let bestPrice = product.price;
                let bestPickupPrice = product.price;
                let bestDeliveryPrice = product.price;

                // Find all affecting discounts and calculate
                let active_discounts = discounts.filter((d) => d.discountType === 'MenuItem');
                active_discounts.map((discount) => {
                    if (discount.allowedItems.includes(product.id) || discount.isAllItems) {
                        const { isDelivery, isPickup, discountPercentage } = discount;
                        let discountedPrice = Math.ceil(product.price - product.price * (discountPercentage / 100));
                        if (isDelivery && isPickup) {
                            bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                            bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);
                        } else if (isDelivery) {
                            bestDeliveryPrice = Math.min(discountedPrice, bestDeliveryPrice);
                        } else if (isPickup) {
                            bestPickupPrice = Math.min(discountedPrice, bestPickupPrice);
                        }
                    }
                });

                // Return the lowest price
                product.deliveryPrice = bestDeliveryPrice;
                product.pickupPrice = bestPickupPrice;
            }
        }
        return product;
    };

    const currentCategory = menuCategories?.find((c) => c.slug === category);

    const Productize = ({ products }) =>
        products?.length ? (
            products
                ?.sort((a, b) => a.price - b.price)
                .sort((a, b) => a.category - b.category)
                .sort((a, b) => b.availableToOrder - a.availableToOrder)
                .map((product) => (
                    <Product
                        title={product.name[lang]}
                        description={product.description && product.description[lang]}
                        price={
                            currentCategory?.catalogueOnly
                                ? null
                                : category === 'pizzas'
                                    ? transcript[lang].order
                                    : `${formatISK(applyMenuItemDiscount(product))} kr.`
                        }
                        variantPrices={applyPizzaDiscount(product)?.map((e) => ({ ...e, price: formatISK(e.price) }))}
                        image={LargeImage(product.imagePublicId)}
                        action={
                            currentCategory?.catalogueOnly
                                ? null
                                : () => (product.action ? product.action(product) : selectProduct(product))
                        }
                        pizza={category === 'pizzas' || category === 'offers'}
                        icon={ShopIcon}
                        disabled={category === 'offers' ? !product.availableToOrder : false}
                        message={
                            currentCategory?.catalogueOnly
                                ? transcript[lang].comingsoon
                                : category === 'offers'
                                    ? product.isDelivery && !product.isPickup
                                        ? transcript[lang].deliveryOnly
                                        : !product.isDelivery && product.isPickup
                                            ? transcript[lang].pickupOnly
                                            : ''
                                    : hasValidDiscounts(product)
                        }
                    />
                ))
        ) : (
                <></>
            );

    return (
        <main>
            <Helmet>
                <title>
                    Castello -{' '}
                    {category === 'pizzas'
                        ? 'Pizzur'
                        : category === 'offers'
                            ? 'Tilboð'
                            : menuCategories?.find((c) => c.slug === category)?.name['is'] || ''}
                </title>
            </Helmet>
            {/* ProductNavigation */}
            <ProductNavigation
                paths={[
                    { label: transcript[lang].offer, value: 'offers' },
                    { label: transcript[lang].pizzas, value: 'pizzas' },
                    ...menuCategories.map((cat) => ({ value: cat.slug, label: cat.name[lang] })),
                ]}
                currentPath={category}
                onChange={selectMenuCategory}
            />
            {/* Toolbar */}
            {category === 'pizzas' ? (
                <ToolBar
                    leftLabel={transcript[lang].divide}
                    leftAction={(e) => props.history.push({ pathname: 'pizza/margarita', search: '?divide=true' })}
                    rightLabel={transcript[lang].chooseToppings}
                    rightAction={(e) => props.history.push({ pathname: 'pizza/margarita', search: '?toppings=true' })}
                />
            ) : (
                    <br />
                )}

            {/* Productcontainer */}
            {category === 'offers' ? (
                <>
                    <Section wide title={transcript[lang].pickup}></Section>
                    {pizzaOffers && (
                        <ProductGrid>
                            {' '}
                            <Productize products={pizzaOffers} />{' '}
                        </ProductGrid>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Section wide title={transcript[lang].delivered}></Section>
                    {deliveryPizzaOffers && (
                        <ProductGrid>
                            {' '}
                            <Productize products={deliveryPizzaOffers} />{' '}
                        </ProductGrid>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Section wide title={transcript[lang].kebaboffers}></Section>
                    {kebabOffers && (
                        <ProductGrid>
                            {' '}
                            <Productize products={kebabOffers} />{' '}
                        </ProductGrid>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <Section wide title={transcript[lang].burgeroffers}></Section>
                    {burgerOffers && (
                        <ProductGrid>
                            {' '}
                            <Productize products={burgerOffers} />{' '}
                        </ProductGrid>
                    )}
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {otherOffers && (
                        <ProductGrid>
                            {' '}
                            <Productize products={otherOffers} />{' '}
                        </ProductGrid>
                    )}
                </>
            ) : (
                    <ProductGrid>
                        {/* List of products */}
                        <Productize products={products} />
                    </ProductGrid>
                )}
        </main>
    );
};

export default CategoryPage;
