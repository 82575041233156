import gql from 'graphql-tag';

export const REQUEST_CODE = gql`
    mutation requestCode($input: UserInputType!) {
        requestCode(userInputType: $input) {
            message
        }
    }
`;

export const AUTH_USER = gql`
    mutation userAuth($input: LoginUserInputType!) {
        userAuth(userInputType: $input) {
            id
            phone
            name
            token
        }
    }
`;

export const CALCULATE_SINGLE_PIZZA = gql`
    mutation calculateSinglePizza($input: UserOrderPizzaCalculateInputType!) {
        calculateSinglePizza(calculatePizzaInput: $input) {
            price
        }
    }
`;

export const REFRESH_CART = gql`
    mutation refreshCart($input: UserRefreshCartInputType!) {
        refreshCart(refreshCartInput: $input) {
            prices {
                pickupPrice
                deliveryPrice
            }
            pickupPrice
            deliveryPrice
            deliveryCost
        }
    }
`;

export const CREATE_ORDER = gql`
    mutation createOrder($input: UserOrderInputType!) {
        createOrder(createOrderInput: $input) {
            id
            signature
            merchantReferenceId
        }
    }
`;

export const VALIDATE_SIGNATURE = gql`
    mutation validateSignature($order: ID!, $signature: String!, $authorizationNumber: String!) {
        validateSignature(order: $order, signature: $signature, authorizationNumber: $authorizationNumber) {
            id
        }
    }
`;
