import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// Redux
import ApolloProvider from './graphql/apolloProvider';
import { Provider } from 'react-redux';
import { initializeStore, initializePersistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';

// UI
import { Header, Cart, Notice } from './components/UI';
import SideMenu from './components/SideMenu';
import PizzaBox from './components/PizzaBox';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Util
import { Helmet } from 'react-helmet';
import GoogleTagManager from './components/GoogleTagManager';
import ScrollToTop from './components/ScrollToTop';
import { initGA, initPixel, logPageView } from './components/Analytics';

// Views
import LandingPage from './pages/LandingPage';
import CategoryPage from './pages/CategoryPage';
import OverviewPage from './pages/OverviewPage';
import LocationsPage from './pages/LocationsPage';
import PizzaPage from './pages/PizzaPage';
import OfferPage from './pages/OfferPage';
import CheckoutPage from './pages/CheckoutPage';
import OrderPage from './pages/OrderPage';
import PolicyPage from './pages/PolicyPage';
import AllergiesPage from './pages/AllergiesPage';

// Debug
import Debug from './pages/Debug';

// Assets
import CastelloLogo from './assets/castello_logo.svg';
import BurgerIcon from './assets/icons/burger.svg';
import ClosedIcon from './assets/icons/close_door.svg';
import DisconnectIcon from './assets/icons/disconnect.svg';

initGA();
initPixel();

const store = initializeStore();
const persistor = initializePersistor(store);
const history = createBrowserHistory();
history.listen((location) => {
    logPageView(location.pathname + location.search);
});

const url = 'https://castello.is/';
const slogan = 'Castello pizzur';
const twitterImage = ''; //TODO: import correct
const ogImage = ''; //TODO: import correct

function App() {
    const [drawer, setDrawer] = useState();
    const [cartVisible, setCartVisible] = useState(false);

    function toggleCartVisible() {
        setCartVisible(!cartVisible);
    }

    return (
        <ApolloProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <div id="app">
                        <div>
                            <GoogleTagManager gtmId="GTM-WH9CPZ6" />
                        </div>
                        {!navigator.onLine && <Notice icon={DisconnectIcon} message={'Engin nettenging'} />}
                        {/* Head meta tags etc.. */}
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>Castello</title>
                            <link rel="canonical" href={url} />

                            <meta name="description" content={slogan} />
                            <meta name="keywords" content={`Castello, pizza, pitsa`} />

                            {/* Twitter */}
                            <meta name="twitter:card" content="summary_large_image" />
                            <meta name="twitter:site" content="@castello" />
                            <meta name="twitter:creator" content="@castello" />
                            <meta name="twitter:title" content={'Castello'} />
                            <meta name="twitter:description" content={slogan} />
                            <meta name="twitter:image" content={twitterImage} />

                            {/* Facebook / Pinterest */}
                            <meta name="og:title" content="Castello" />
                            <meta name="og:type" content="website" />
                            <meta name="og:url" content={url} />
                            <meta name="og:image" content={ogImage} />
                            <meta name="og:description" content={slogan} />
                        </Helmet>
                        <Router history={history}>
                            <ScrollToTop>
                                <Header
                                    logo={CastelloLogo}
                                    leftIcon={BurgerIcon}
                                    leftAction={() => setDrawer(!drawer)}
                                    rightIcon={<PizzaBox />}
                                    rightAction={toggleCartVisible}
                                    notificationIcon={ClosedIcon}
                                    notificationText={'Ekki er hægt að panta þegar allir staðir eru lokaðir'}
                                />
                                <Switch>
                                    <Route exact path="/" component={LandingPage} />
                                    {/* <Route path="/debug" component={Debug} /> */}
                                    <Route path="/overview" component={OverviewPage} />
                                    <Route path="/locations" component={LocationsPage} />
                                    <Route path="/checkout" component={CheckoutPage} />
                                    <Route path="/order/:id" component={OrderPage} />
                                    <Route path="/policy" component={PolicyPage} />
                                    <Route path="/allergies" component={AllergiesPage} />
                                    {/* Keep last for routing-priority */}
                                    <Route path="/offer/:slug" component={OfferPage} />
                                    <Route path="/pizza/:slug" component={PizzaPage} />
                                    <Route path="/:slug" component={CategoryPage} />
                                </Switch>
                            </ScrollToTop>
                            <Cart visible={cartVisible} toggle={toggleCartVisible} />
                            {!!drawer && <SideMenu onClose={() => setDrawer(false)} />}
                        </Router>
                    </div>
                </PersistGate>
            </Provider>
            <ToastContainer autoClose={2500} />
        </ApolloProvider>
    );
}

export default App;
