import { useQuery } from '@apollo/react-hooks'
import { ALL_BRANCHES } from '../graphql/queries'

const weekdays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const isOpen = (branch) => {
    const day = new Date().getDay();
    const hour = new Date().getHours();
    const minute = new Date().getMinutes()

    let open = true;
    let openingHours = branch.openingHours[weekdays[day]];

    if (openingHours.isClosed) {
        return false;
    }

    const hourFrom = Number(openingHours.from.slice(0, 2));
    const minuteFrom = Number(openingHours.from.slice(2, 4));

    const hourTo = Number(openingHours.to.slice(0, 2));
    const minuteTo = Number(openingHours.to.slice(2, 4));

    if (hourFrom >= hour) {
        open = (hourFrom === hour && minuteFrom < minute);
    }

    if (hourTo <= hour) {
        open = (hourTo === hour && minuteTo > minute);
    }

    return open;
}

const isDelivering = (branch) => {
    const day = new Date().getDay();
    const hour = new Date().getHours();
    const minute = new Date().getMinutes()

    let open = true;
    let openingHours = branch.openingHours[weekdays[day]];
    if (openingHours.isClosed) {
        return false;
    }

    const stopDeliverHours = Math.floor(branch.minutesBeforeOnlineSalesClose / 60);
    const stopDeliverMinutes = Math.round(branch.minutesBeforeOnlineSalesClose * (stopDeliverHours ? (branch.minutesBeforeOnlineSalesClose / 60 - stopDeliverHours) : 1));

    const hourFrom = Number(openingHours.from.slice(0, 2));
    const minuteFrom = Number(openingHours.from.slice(2, 4));

    const hourTo = Number(openingHours.to.slice(0, 2)) - stopDeliverHours;
    const minuteTo = Number(openingHours.to.slice(2, 4)) - stopDeliverMinutes;


    if (hourFrom >= hour) {
        open = (hourFrom === hour && minuteFrom < minute);
    }

    if (hourTo <= hour) {
        open = (hourTo === hour && minuteTo > minute);
    }

    return open;
}

function useBranches() {
    const { error, data, loading, refetch } = useQuery(ALL_BRANCHES, {
        notifyOnNetworkStatusChange: true,
    });

    if (loading && !data?.allBranches) return { loading, branches: [], totalCount: null };
    if (error) return { loading, branches: [], totalCount: null }

    return {
        branches: data.allBranches.edges.map(({ node }, i) => {
            return {
                ...node,
                open: isOpen(node),
                delivering: isDelivering(node)
            }
        }),
        totalCount: data.allBranches.totalCount,
        loading,
        refetch,
    };
}

export default useBranches;