import { useQuery } from '@apollo/react-hooks'
import { CASTELLO_SETTINGS } from '../graphql/queries'

function useCastelloSettings() {
  const { error, data, loading, refetch } = useQuery(CASTELLO_SETTINGS, {
    notifyOnNetworkStatusChange: true,
  });
  if (loading && !data?.getCastelloSettings) return { loading, settings: null };
  if (error) return { loading, settings: null }

  return {
    settings: data.getCastelloSettings,
    loading,
    refetch,
  };
}

export default useCastelloSettings;