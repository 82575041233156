import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useOffers, useToppings, usePizzas, useMenuItems, useCastelloSettings } from '../hooks';
import { Section, Form, CheckGroup, HotBar, DropTitle, Button, Tabs } from '../components/UI';
import { toast } from 'react-toastify';
import { addToCart } from '../redux/actions/cart';

import { formatISK } from '../services/prices';

import transcript from '../i18n/OfferPage';
import { useSelector } from 'react-redux';

import DivisionIcon from '../assets/icons/division';
import division from '../assets/icons/division';

const sizeChart = {
    small: '9"',
    medium: '12"',
    large: '16"',
};

const OfferPage = (props) => {
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang);

    // Fetch data
    const { toppings } = useToppings();
    const flatToppings = toppings.map((t) => t.toppings).flat();
    const { settings } = useCastelloSettings();
    const { offers } = useOffers();
    const { pizzas, loading: pizzasLoading } = usePizzas();
    const { menuItems } = useMenuItems();

    // Current offer
    const slug = props.match.params.slug;
    const offer = offers.find((offer) => offer.slug === slug);
    const cart = useSelector((state) => state.cart);

    // **************************************
    // Pizza topping handlers
    // **************************************
    const [toppingsSelection, setToppingSelection] = useState();
    const [pizzaSelection, setPizzaSelection] = useState([]);
    const [itemSelection, setItemSelection] = useState([]);
    const [divide, setDivide] = useState([]);

    const selectTopping = (i, val) => {
        let half = divide[i] ? divide[i] - 1 : 0;
        let currentToppingSelection = toppingsSelection[i];
        let toppingsSelectionCopy = [...toppingsSelection];
        let toppingAmount = divide[i] ? offer.details[i].toppingAmount * 2 : offer.details[i].toppingAmount;

        if (currentToppingSelection[half].map((value) => value.id).includes(val)) {
            // Removing
            currentToppingSelection[half] = currentToppingSelection[half].filter((topping) => topping.id !== val);
            toppingsSelectionCopy.splice(i, 1, currentToppingSelection);
        } else {
            // Adding
            const pricedTopping = flatToppings.find((topping) => val === topping.id);
            if (
                wholeSideToppingCount(i) < toppingAmount ||
                settings.baseToppings.includes(val) ||
                pricedTopping.priceLarge === 0
            ) {
                //If you can add
                currentToppingSelection[half] = [...currentToppingSelection[half], { id: val, amount: 1 }];
                toppingsSelectionCopy.splice(i, 1, currentToppingSelection);
            }
        }

        setToppingSelection(toppingsSelectionCopy);
    };

    const toggleDivision = (i) => {
        let currentDivision = divide;
        currentDivision[i] = currentDivision[i] ? 0 : 1;
        setDivide([...currentDivision]);

        // if untoggling
        if (!currentDivision[i]) {
            toppingsSelection[i][1] = [];
        }
    };

    const selectHalf = (i, half) => {
        let currentDivision = divide;
        currentDivision[i] = half;
        setDivide([...currentDivision]);
    };

    const selectToppingAmount = (i, val) => {
        let half = divide[i] ? divide[i] - 1 : 0;
        let toppingAmount = divide[i] ? offer.details[i].toppingAmount * 2 : offer.details[i].toppingAmount;

        let currentToppingSelection = toppingsSelection[i];
        let toppingsSelectionCopy = [...toppingsSelection];

        // Check if topping is already on selected half
        const pricedTopping = flatToppings.find((topping) => val === topping.id);
        const currentTopping = currentToppingSelection[half].find((topping) => topping.id === val);
        if (currentTopping) {
            currentToppingSelection[half] = [
                ...currentToppingSelection[half].filter((topping) => topping.id !== val),
                {
                    id: val,
                    amount:
                        currentTopping.amount > 1
                            ? 1
                            : wholeSideToppingCount(i) < toppingAmount || pricedTopping.priceLarge === 0
                            ? 2
                            : 1,
                },
            ];
            toppingsSelectionCopy.splice(i, 1, currentToppingSelection);
        } else {
            // Adding 2x topping that was not before
            if (wholeSideToppingCount(i) < toppingAmount - 1 || pricedTopping.priceLarge === 0) {
                currentToppingSelection[half] = [...currentToppingSelection[half], { id: val, amount: 2 }];
                toppingsSelectionCopy.splice(i, 1, currentToppingSelection);
            }
        }

        setToppingSelection(toppingsSelectionCopy);
    };

    const toppingCount = (i, side) => {
        // find if base toppings have 2x
        let extraBase = toppingsSelection[i][side].filter(
            (topping) => settings.baseToppings.includes(topping.id) && topping.amount === 2
        );
        let pricedToppings = toppingsSelection[i][side || 0].map((t) => ({
            ...t,
            flat: flatToppings.find((topping) => t.id === topping.id),
        }));
        return (
            pricedToppings
                .filter((t) => !settings.baseToppings.includes(t.id) && t.flat.priceLarge > 0)
                .reduce((acc, curr) => acc + (curr?.amount || 1), 0) + extraBase.length
        );
    };

    const wholeSideToppingCount = (i) => {
        // Find extra base
        let sum = 0;
        let extraBase = toppingsSelection[i]
            .flat()
            .filter((topping) => settings.baseToppings.includes(topping.id) && topping.amount === 2);

        sum += extraBase.length;

        let pricedToppings = toppingsSelection[i].flat().map((t) => ({
            ...t,
            flat: flatToppings.find((topping) => t.id === topping.id),
        }));

        sum += pricedToppings
            .filter((t) => !settings.baseToppings.includes(t.id) && t.flat.priceLarge > 0)
            .reduce((acc, curr) => acc + (curr?.amount || 1), 0);

        return sum;
    };

    // **************************************

    // **************************************
    // Menu pizza/item selection handlers
    // **************************************
    const handlePizzaChange = (pizza, i) => {
        let pizzaSelectionTemp = [...pizzaSelection];
        pizzaSelectionTemp[i] = pizza; // = pizzaSelectionTemp.splice(i, 1, pizza.slug);

        setPizzaSelection(pizzaSelectionTemp);
    };

    const handleItemChange = (item, i) => {
        let itemSelectionTemp = [...itemSelection];
        itemSelectionTemp[i] = item; // = pizzaSelectionTemp.splice(i, 1, pizza.slug);

        setItemSelection(itemSelectionTemp);
    };

    // **************************************
    // Helpers
    // **************************************
    // Detail filler
    useEffect(() => {
        if (offer && settings && (!toppingsSelection?.length || !pizzaSelection || !itemSelection)) {
            setToppingSelection(
                new Array(offer?.details?.length).fill([
                    settings.baseToppings.map((t) => ({ id: t, amount: 1 })),
                    settings.baseToppings.map((t) => ({ id: t, amount: 1 })),
                ])
            );
            setDivide(new Array(offer?.details?.length).fill(null));
            setPizzaSelection(new Array(offer?.details?.length).fill(null));
            setItemSelection(new Array(offer?.details?.length).fill(null));
        }
    }, [itemSelection, offer, pizzaSelection, settings, toppingsSelection]);

    const generateCorrectDetails = () => {
        return offer.details.map(({ offerType, isMenuPizza, size }, index) => {
            if (offerType === 'Pizza') {
                let toppings, toppings2;
                let id = null;
                if (isMenuPizza) {
                    let currPizzaSelection = pizzaSelection[index];
                    if (currPizzaSelection === null) {
                        throw new Error(transcript[lang].missingSelection.replace('%%', index + 1));
                    }
                    toppings = currPizzaSelection.toppings.map(({ amount, topping: { id } }) => {
                        return {
                            toppingId: id,
                            amount,
                        };
                    });
                    id = currPizzaSelection.id;
                } else {
                    toppings = toppingsSelection[index][0].map(({ id, amount }, index) => {
                        return {
                            toppingId: id,
                            amount,
                        };
                    });

                    toppings2 =
                        divide[index] &&
                        toppingsSelection[index][1].map(({ id, amount }, index) => {
                            return {
                                toppingId: id,
                                amount,
                            };
                        });
                }
                return {
                    type: offerType,
                    id,
                    size,
                    toppings,
                    toppings2,
                    isDivided: !!divide[index],
                };
            } else if (offerType === 'MenuItem') {
                let currItemSelection = itemSelection[index];
                if (currItemSelection === null) {
                    throw new Error(transcript[lang].missingSelection);
                }
                return {
                    type: offerType,
                    id: currItemSelection?.id,
                    size: null,
                    toppings: null,
                    toppings2: null,
                    isDivided: false,
                };
            }
        });
    };

    const handleAddToCart = () => {
        let flatMenuItems = menuItems.map((c) => c.items).flat();
        // Disallow pickup and delivery orders in same cart

        // Checks if cart contains contradictory offer
        if (offer.isDelivery && !offer.isPickup) {
            let contradictoryOffers = cart.find((item) => item.pickupAvail && !item.deliveryAvail);
            if (contradictoryOffers) {
                toast(transcript[lang].notApplicable, { type: 'error' });
                return;
            }
        }

        if (offer.isPickup && !offer.isDelivery) {
            let contradictoryOffers = cart.find((item) => item.deliveryAvail && !item.pickupAvail);
            if (contradictoryOffers) {
                toast(transcript[lang].notApplicable, { type: 'error' });
                return;
            }
        }

        try {
            let correctDetails = generateCorrectDetails();
            dispatch(
                addToCart({
                    type: 'Offer',
                    id: offer.id,
                    name: offer.name,
                    description: correctDetails
                        .map((detail) => {
                            if (detail.type === 'Pizza') {
                                return `Pizza m. ${detail.toppings
                                    .map((topping) => flatToppings.find((t) => t.id == topping.toppingId)?.name[lang])
                                    .join(', ')} ${
                                    detail.toppings2
                                        ? ' // ' +
                                          detail.toppings2
                                              .map(
                                                  (topping) =>
                                                      flatToppings.find((t) => t.id == topping.toppingId).name[lang]
                                              )
                                              .join(', ')
                                        : ''
                                }`;
                            } else {
                                return flatMenuItems.find((m) => m.id === detail.id).name[lang];
                            }
                        })
                        .join(' - '),
                    details: correctDetails,
                    price: offer.price,
                    pickupAvail: offer.isPickup,
                    deliveryAvail: offer.isDelivery,
                    deliveryPrice: offer.price,
                    pickupPrice: offer.price,
                })
            );
            toast(transcript[lang].addedToCart, { type: 'success' });
            props.history.push({
                pathname: `/offers`,
            });
        } catch (err) {
            console.log(err);
            toast(err?.message, { type: 'error' });
        }
    };

    // Renderers
    const menuItemDetail = (detail, i) => {
        let flatMenuItems = menuItems.map((c) => c.items).flat();

        const availableItems = flatMenuItems
            .filter((item) => (detail.allowedItems.length ? detail.allowedItems.includes(item.id) : true))
            .map((item) => ({ value: item?.slug, label: item?.name[lang] }))
            .flat();

        if (!itemSelection[i]) {
            availableItems.unshift({ value: null, label: transcript[lang].chooseMenuitem });
        }

        return (
            <>
                <p>
                    <b>
                        {transcript[lang].offerItem}: {i + 1}
                    </b>{' '}
                    {sizeChart[detail.size]}
                </p>
                <DropTitle
                    title={itemSelection[i]?.name[lang] || transcript[lang].choosePizza}
                    options={availableItems}
                    value={itemSelection[i]?.slug || ''}
                    onChange={(slug) =>
                        handleItemChange(
                            flatMenuItems.find((p) => p.slug === slug),
                            i
                        )
                    }
                />
                <br />
                <br />
            </>
        );
    };

    const pizzaDetail = (detail, i) => {
        if (detail.isMenuPizza) {
            const availablePizzas = pizzas
                .filter((pizza) => (detail.allowedItems?.length ? detail.allowedItems.includes(pizza.id) : true))
                .map((pizza) => ({ value: pizza?.slug, label: pizza?.name[lang] }));

            if (!pizzaSelection[i]) {
                availablePizzas.unshift({ value: null, label: transcript[lang].choosePizza });
            }

            return (
                <>
                    <p>
                        <b>
                            {transcript[lang].offerItem}: {i + 1}
                        </b>{' '}
                        {sizeChart[detail.size]} {transcript[lang].pizzaFromMenu}
                    </p>
                    <DropTitle
                        title={pizzaSelection[i]?.name[lang] || transcript[lang].choosePizza}
                        options={availablePizzas}
                        value={pizzaSelection[i]?.slug || ''}
                        onChange={(slug) =>
                            handlePizzaChange(
                                pizzas.find((p) => p.slug === slug),
                                i
                            )
                        }
                    />
                    <br />
                    <br />
                </>
            );
        } else {
            return (
                <>
                    <p>
                        <b>
                            {transcript[lang].offerItem}: {i + 1}
                        </b>{' '}
                        {sizeChart[detail.size]}{' '}
                        {transcript[lang].pizzaWithToppings.replace('%%', detail.toppingAmount)}
                    </p>
                    <Button
                        onClick={(e) => toggleDivision(i)}
                        active={divide[i]}
                        icon={<DivisionIcon color={divide[i] ? '#fff' : '#b51430'} />}
                    >
                        {transcript[lang].divide}
                    </Button>
                    {/* Half selection */}
                    {!!divide[i] && (
                        <Tabs
                            options={[
                                { value: 1, label: `${transcript[lang].nthHalf.replace('%%', 1)}` },
                                { value: 2, label: `${transcript[lang].nthHalf.replace('%%', 2)}` },
                            ]}
                            active={divide[i]}
                            setActive={(val) => selectHalf(i, val)}
                        />
                    )}

                    <>
                        {toppings?.map((toppingGroup) => (
                            <CheckGroup
                                title={toppingGroup.category.name[lang]}
                                options={toppingGroup.toppings.map((topping) => ({
                                    id: topping.id,
                                    label: topping.name[lang],
                                    secondary: topping.maxAmount > 1,
                                }))}
                                values={toppingsSelection ? toppingsSelection[i][divide[i] ? divide[i] - 1 : 0] : []}
                                toggle={(val) => selectTopping(i, val)}
                                secondaryToggle={(val) => selectToppingAmount(i, val)}
                                secondaryLabel="2X"
                                valueKey="id"
                            />
                        ))}
                    </>
                    <br />
                    <br />
                </>
            );
        }
    };

    if (!toppings || !offers || !pizzas || !menuItems || !settings) {
        return <p>loading</p>;
    }

    return (
        <>
            <Section wide title={offer?.name[lang]}>
                {offer?.details.map((detail, i) =>
                    detail.offerType === 'MenuItem' ? menuItemDetail(detail, i) : pizzaDetail(detail, i)
                )}
            </Section>
            <HotBar
                title={transcript[lang].addToCart}
                subtitle={`${offer ? formatISK(offer.price) : ''} kr.`}
                action={handleAddToCart}
            />
        </>
    );
};

export default OfferPage;
