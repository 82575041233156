import React from 'react';
import './Form.scss';

const Form = ({
    title,
    subtitle,
    children,
    ctaTitle,
    ctaAction,
    ctaIcon,
    secondaryCtaTitle,
    secondaryCtaAction,
    secondaryCtaIcon,
    disabled,
    secondaryDisabled,
    ...rest
}) => (
    <form className="form" onSubmit={(e) => e.preventDefault()} {...rest}>
        {title && <h1>{title}</h1>}
        {subtitle && <h3>{subtitle}</h3>}
        {children}
        {secondaryCtaTitle && secondaryCtaAction ? (
            <div className="dual">
                <button className={`cta ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && ctaAction()}>
                    {!!ctaIcon && <img src={ctaIcon} alt={ctaTitle} />}
                    {ctaTitle}
                </button>
                <button
                    className={`cta ${secondaryDisabled ? 'disabled' : ''}`}
                    onClick={() => !secondaryDisabled && secondaryCtaAction()}
                >
                    {!!secondaryCtaIcon && <img src={secondaryCtaIcon} alt={secondaryCtaTitle} />}
                    {secondaryCtaTitle}
                </button>
            </div>
        ) : (
            ctaTitle && (
                <button className={`cta ${disabled ? 'disabled' : ''}`} onClick={() => !disabled && ctaAction()}>
                    {!!ctaIcon && <img src={ctaIcon} alt={ctaTitle} />}
                    {ctaTitle}
                </button>
            )
        )}
    </form>
);

export default Form;
