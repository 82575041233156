import React, { useEffect } from 'react';
import { Section, Form, CartItems } from '../components/UI';
import { useQuery } from '@apollo/react-hooks'
import { MY_ORDER } from '../graphql/queries'
import { useBranches, useCastelloSettings } from '../hooks';
import { useSelector } from 'react-redux';

import { useToppings } from '../hooks';
import transcript from '../i18n/OrderPage.json';


const formatISK = price => price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0';
const OrderPage = (props) => {
    // TODO: get branch waiting time
    // const { settings } = useCastelloSettings();

    const lang = useSelector(state => state.lang);

    // Fetching order
    const orderId = props.match.params.id;
    const { data } = useQuery(MY_ORDER, { variables: { id: orderId } });


    const { toppings } = useToppings();
    const flatToppings = toppings.map(t => t.toppings).flat();


    useEffect(() => {
        if (!data) {
        } else {
            if (data.myOrder.paymentMethod === 'netgiro' && data.myOrder.status === 'due') {
                // TODO: validate netgiro payload
            }
        }
    }, [data])

    return (
        <Section title={transcript[lang].orderComplete} subtitle={(data?.myOrder.deliveryMethod === 'pickup' ? transcript[lang].waitingTime : transcript[lang].deliveryTime).replace('%%', (data?.myOrder.deliveryMethod === 'pickup' ? data?.myOrder.branch.estimatedPickupTime : data?.myOrder.branch.estimatedDeliveryTime))}>
            {
                data &&
                <Form
                    title={data?.myOrder.deliveryMethod === 'pickup' ? transcript[lang].pickup : transcript[lang].delivery}
                    subtitle={data?.myOrder.branch.name}
                >

                    <CartItems cart={data?.myOrder.cart.map(item => {
                        const { payload1, payload2 } = item;

                        if (payload1) {
                            item.baseToppings1 = JSON.parse(payload1).toppings?.map(t => {
                                let topping = { ...t };
                                topping.topping.id = topping.topping._id;
                                return topping;
                            });
                        }

                        if (payload2) {
                            item.baseToppings2 = JSON.parse(payload2).toppings?.map(t => {
                                let topping = { ...t };
                                topping.topping.id = topping.topping._id;
                                return topping;
                            });
                        }

                        item.toppingsSelectionSide1 = item.toppingsSelectionSide1?.map(t => ({ ...t, id: t.toppingId }));
                        item.toppingsSelectionSide2 = item.toppingsSelectionSide2?.map(t => ({ ...t, id: t.toppingId }));


                        return {
                            ...item,
                        }
                    })} lang={lang} fixed={false} />
                    {!!data?.myOrder?.totalDeliveryPrice && <p>{transcript[lang].shipping} <b>{formatISK(data?.myOrder?.totalDeliveryPrice)} kr.</b></p>}
                    <p>{transcript[lang].total} <b>{formatISK(data?.myOrder?.totalPrice)} kr.</b></p>
                </Form>
            }
        </Section>
    )
}

export default OrderPage;