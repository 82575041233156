import React from "react";
import { useMenuCategories } from "../hooks";
import { Drawer } from "./UI";

import PizzaThrow from "../assets/images/pizza_throw.svg";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "../redux/actions/lang";

import facebookIcon from "../assets/icons/facebook.svg";

// i18n
import transcript from "../i18n/SideMenu";

const SideMenu = ({ onClose }) => {
  const { menuCategories } = useMenuCategories();
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();

  const links = [
    { label: transcript[lang].offers, path: "/offers" },
    { label: transcript[lang].pizzas, path: "/pizzas" },
    ...menuCategories.map((cat) => ({
      path: `/${cat.slug}`,
      label: cat.name[lang],
    })),
    { label: lang === "is" ? "Staðir" : "Locations", path: "/locations" },
    {
      label: lang === "is" ? "Skilmálar" : "Terms & Conditions",
      path: "/policy",
    },
    {
      label: lang === "is" ? "Ofnæmisvaldar" : "Allergies",
      path: "/allergies",
    },
  ];

  const switchLang = () => {
    dispatch(changeLanguage(lang === "is" ? "en" : "is"));
  };

  return (
    <Drawer
      links={links}
      footer={
        <>
          <img src={PizzaThrow} alt="Castello" />
          <b>
            <a href="tel:+3545773333">s. 577 3333</a>
          </b>
        </>
      }
      action={switchLang}
      actionLabel={lang === "is" ? "English" : "Íslenska"}
      secondaryAction={"https://www.facebook.com/castellopizzeria"}
      secondaryActionImage={facebookIcon}
      onClose={onClose}
    />
  );
};

export default SideMenu;
